import { useState, useEffect } from 'react'
import { dateTimeFormat } from '../utils/formatter'
import ReactTable from './ReactTable'
import LambdaFetch from '../functions/FetchFromLambda'
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment
} from '@material-ui/core'
import LoadingButton from '../components/LoadingButton'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'

const logCols = [
  {
    accessorKey: 'date',
    header: 'Date/Time',
    sortingFn: 'datetime',
    Cell: ({ cell }) => dateTimeFormat(cell.getValue())
  },
  {
    accessorKey: 'action',
    header: 'Action',
    filterFn: 'contains'
  },
  {
    accessorKey: 'page',
    header: 'Page',
    filterFn: 'contains'
  },
  {
    accessorKey: 'user',
    header: 'User',
    filterFn: 'contains'
  },
  {
    accessorKey: 'payloadDesc',
    header: 'Payload',
    filterFn: 'contains'
  },
  {
    accessorKey: 'desc',
    header: 'Description',
    filterFn: 'contains'
  }
]

const Logs = props => {
  const [state, setstate] = useState({
    isLoading: true,
    isFetching: true, // TODO
    logs: null
  })
  const [headers, setHeaders] = useState({
    action: null,
    user: null,
    dateFrom: null,
    dateTo: null
  })
  const [headerOptions, setHeaderOptions] = useState({
    action: ['deny', 'download', 'error', 'login', 'logout'],
    user: []
  })
  const [params, setParams] = useState(null)

  useEffect(() => {
    async function fetchMyAPI () {
      fetchData(headers)
      getUsers()
    }
    fetchMyAPI()
  }, [])

  const getUsers = async () => {
    try {
      const resp = await LambdaFetch(
        'admin',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: 'read-audit-users'
        }),
        '',
        props.fetchInitialData.credentials
      )
      setHeaderOptions({
        ...headerOptions,
        user: resp.data.users.map(row => row.user)
      })
    } catch (e) {
      console.log(e)
    }
  }

  const fetchData = async headers => {
    try {
      const resp = await LambdaFetch(
        'admin',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: 'read-audit',
          headers
        }),
        '',
        props.fetchInitialData.credentials
      )

      setstate({
        ...state,
        logs: resp.data.audit.map(row => {
          return {
            ...row,
            payloadDesc: row.payload ? (
              <div
                className='editLink'
                onClick={() => {
                  setParams(row.payload)
                }}
              >
                {`${row.payload.slice(0, 50)}......`}
              </div>
            ) : (
              ''
            ),
            desc: row.description ? (
              <div
                className='editLink'
                onClick={() => {
                  setParams(JSON.stringify({ description: row.description }))
                }}
              >
                {`${row.description.slice(0, 50)}......`}
              </div>
            ) : (
              ''
            )
          }
        }),
        isLoading: false,
        isFetching: false
      })
    } catch (e) {
      console.log(e)
    }
  }
  const handleChange = (name, value) => {
    setHeaders({ ...headers, [name]: !!value ? value : null })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setstate({...state, isLoading: true})
    fetchData(headers)
  }
  return (
    <>
      {params && (
        <Dialog
          fullWidth
          scroll={'paper'}
          open={params}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogContent dividers>
            <RenderParams data={JSON.parse(params)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setParams(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      <div style={{ margin: '1rem' }}>
        <Paper elevation={0} style={{ padding: '1rem', marginBottom: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={2}
            >
              {[
                {
                  id: 'action',
                  label: 'Action',
                  optionLabel: val => `${val}`,
                  optionRender: null,
                  required: false
                },
                {
                  id: 'user',
                  label: 'User',
                  optionLabel: val => `${val}`,
                  optionRender: null,
                  required: false
                }
              ].map(field => {
                const renderOp = !!field.optionLabel
                  ? field.optionLabel
                  : option => {
                      return (
                        <div>
                          <Typography
                            style={{ fontSize: '13px' }}
                            variant='body1'
                            color='textPrimary'
                          >
                            {`${option.id}`}
                          </Typography>
                        </div>
                      )
                    }
                const optionLabel = field.optionLabel
                  ? field.optionLabel
                  : val => `${val.id}`
                return (
                  <Grid key={field.id} item>
                    <SimpleAutoComplete
                      id={field.id}
                      required={field.required}
                      name={field.id}
                      width='175px'
                      variant='standard'
                      label={field.label}
                      value={headers[field.id]}
                      onChange={val => {
                        handleChange(field.id, val)}}
                      options={headerOptions[field.id]}
                      getOptionLabel={optionLabel}
                      renderOption={renderOp}
                    />
                  </Grid>
                )
              })}
              {[
                {
                  id: 'dateFrom',
                  label: 'From Date'
                },
                {
                  id: 'dateTo',
                  label: 'To Date'
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      style={{ marginTop: '5px', width: '175px' }}
                      type={'date'}
                      required={false}
                      label={field.label}
                      variant='standard'
                      fullWidth
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          [field.id]: !!e.target.value ? e.target.value : null
                        })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>

        <ReactTable
          isLoading={state.isFetching}
          cols={logCols}
          data={state.logs}
        />
      </div>
    </>
  )
}

export default Logs

const RenderParams = ({ data, parentKey = null, indentLevel = 0 }) => {
  return (
    <div style={{ marginLeft: `${indentLevel * 20}px` }}>
      {Object.keys(data).map(key => (
        <div key={key}>
          {typeof data[key] !== 'object' ? (
            <Typography variant='body1'>
              {key}: {data[key]}
            </Typography>
          ) : (
            <div>
              {parentKey && <strong>{parentKey}:</strong>} {key}:
              <RenderParams
                data={data[key]}
                parentKey={key}
                indentLevel={indentLevel + 1}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
