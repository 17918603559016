import { currencyFormat, noWrapFormat, dateFormat } from '../utils/formatter'

export const testCols = [
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCT_UNIT',
    label: 'Account Unit',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCT_ND',
    label: 'Account ND',
    options: {
      filter: true,
      sort: true
    }
  }
]

export const glChartCols = [
  {
    name: 'CHART_SECTION',
    label: 'Section',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SEQ_NUMBER',
    label: 'Number',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DEPTH',
    label: 'Depth',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SUMMARY_ACCT',
    label: 'Summary',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT_DESC',
    label: 'Description',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SUB_ACCOUNT',
    label: 'Sub Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT_DESC',
    label: 'Account Description',
    options: {
      filter: true,
      sort: true
    }
  }
]

// export const budgetHeaderCols = [
//   {
//     name: 'FISCAL_YEAR',
//     label: 'Year',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'BUDGET_NBR',
//     label: 'Budget',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'COMPANY',
//     label: 'Company',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'MAINT_DATE',
//     label: 'Date',
//     options: {
//       customBodyRender: dateFormat,
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'DESCRIPTION',
//     label: 'Description',
//     options: {
//       filter: false,
//       sort: false,
//     },
//   },

// ]

// export const budgetCols = [
//   {
//     name: 'PRD',
//     label: 'PRD',
//     options: {
//       filter: false,
//       sort: false,
//     },
//   },

//   {
//     name: 'AMOUNT',
//     label: 'Amount',
//     options: {
//       customBodyRender: currencyFormat,
//       filter: false,
//       sort: true,
//     },
//   },
//     {
//     name: 'UNITS',
//     label: 'Units',
//     options: {
//       filter: false,
//       sort: true,
//     },
//   },
//     {
//     name: 'FACTOR',
//     label: 'Factor',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//     {
//     name: 'RATE',
//     label: 'Rate',
//     options: {
//       filter: true,
//       sort: true,
//     }
//     }
// ]

export const glTransCols = [
  {
    name: 'JE_NUMBER',
    label: 'JE Number',
    options: {
      customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'LINE_NBR',
    label: 'Line',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCT_UNIT',
    label: 'Account Unit',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'UNIT_DESCRIPTION',
    label: 'Unit Desc',
    options: {
      display: false,
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT_DESC',
    label: 'Account Desc',
    options: {
      display: false,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'UNITS_AMOUNT',
    label: 'Units',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'POSTING_DATE',
    label: 'Posting Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_AMOUNT',
    label: 'Tran Amount',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACTIVITY_LINK',
    label: 'Activity',
    options: {
      download: false,
      filter: false,
      sort: true
    }
  },
  {
    name: 'GTMXVALUE',
    label: 'Attributes',
    options: {
      download: false,
      filter: false,
      sort: false
    }
  },
  {
    name: 'MORE',
    label: 'More Info',
    options: {
      download: false,
      filter: false,
      sort: false
    }
  },
  {
    name: 'SOURCE_CODE',
    label: 'Source Code',
    options: {
      display: false,
      filter: true,
      sort: true
    }
  },
  {
    name: 'REFERENCE',
    label: 'Reference',
    options: {
      display: false,
      filter: true,
      sort: true
    }
  },
  {
    name: 'BASE_AMOUNT',
    label: 'Base Amount',
    options: {
      customBodyRender: currencyFormat,
      display: false,
      filter: true,
      sort: true
    }
  },
  {
    name: 'AUTO_REV',
    label: 'Auto Rev',
    options: {
      display: false,
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACTIVITY',
    label: 'Activity',
    options: {
      display: false,
      filter: false,
      sort: false
    }
  },
  {
    name: 'ACCT_CATEGORY',
    label: 'Category',
    options: {
      display: false,
      filter: false,
      sort: false
    }
  },
  {
    name: 'ActCatDescription',
    label: 'Catergory Description',
    options: {
      display: false,
      filter: false,
      sort: false
    }
  },
  {
    name: 'ActDescription',
    label: 'Description',
    options: {
      display: false,
      filter: false,
      sort: false
    }
  }
]

export const moreGlTansCols = [
  {
    name: 'TO_COMPANYY',
    label: 'To Company',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'UNIT_DESCRIPTION',
    label: 'Acct Unit Desc',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT_DESC',
    label: 'Acct Desc',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SOURCE_CODE',
    label: 'Source Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REFERENCE',
    label: 'Reference',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'BASE_AMOUNT',
    label: 'Base Amount',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'AUTO_REV',
    label: 'Auto Rev',
    options: {
      filter: true,
      sort: true
    }
  }
]

export const glActivityCols = [
  {
    name: 'ACTIVITY',
    label: 'Activity',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'ActDescription',
    label: 'Description',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'ACCT_CATEGORY',
    label: 'Category',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'ActCatDescription',
    label: 'Catergory Description',
    options: {
      filter: false,
      sort: false
    }
  }
]

export const attribCols = [
  // {
  //   name: 'OBJ_ID',
  //   label: 'Obj ID',
  //   options: {
  //     filter: true,
  //     sort: true,
  //   },
  // },
  {
    name: 'MATRIX_CAT',
    label: 'Attribute',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'MX_VALUE',
    label: 'Value',
    options: {
      filter: true,
      sort: true
    }
  }
]

// export const trialBalanceCols = [
//   {
//     name: 'COMP',
//     label: 'Co',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'CHART',
//     label: 'Chart',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'FY',
//     label: 'Year',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'PRD',
//     label: 'Prd',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'ACCT_UNIT',
//     label: 'Acct Unit',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'ACCT',
//     label: 'Acct',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'SUB_ACCT',
//     label: 'Sub Acct',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'AU_DESC',
//     label: 'AU',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'ACCT_DESC',
//     label: 'Description',
//     options: {
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'BEG_BAL',
//     label: 'Beg Bal',
//     options: {
//       setCellProps: value => {
//         return {
//           style: { textAlign: 'right' }
//         }
//       },
//       customBodyRender: currencyFormat,
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'DEBITS',
//     label: 'Debits',
//     options: {
//       showTotal: true,
//       setCellProps: value => {
//         return {
//           style: { textAlign: 'right' }
//         }
//       },
//       customBodyRender: currencyFormat,
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'CREDITS',
//     label: 'Credits',
//     options: {
//       setCellProps: value => {
//         return {
//           style: { textAlign: 'right' }
//         }
//       },
//       customBodyRender: currencyFormat,
//       filter: true,
//       sort: true,
//     },
//   },
//   {
//     name: 'END_BAL',
//     label: 'End Bal',
//     options: {
//       setCellProps: value => {
//         return {
//           style: { textAlign: 'right' }
//         }
//       },
//       customBodyRender: currencyFormat,
//       filter: true,
//       sort: true,
//     },
//   },

// ];

export const glTransServerSideCols = [
  {
    name: 'JE_NUMBER',
    label: 'JE Number',
    options: {
      customBodyRender: noWrapFormat,
      sort: false,
      filter: false
    }
  },
  {
    name: 'LINE_NBR',
    label: 'Line',
    options: {
      sort: false,
      filter: false
    }
  },
  {
    name: 'ACCT_UNIT',
    label: 'Account Unit',
    options: {
      sort: false,
      filter: true
    }
  },
  {
    name: 'UNIT_DESCRIPTION',
    label: 'Unit Desc',
    options: {
      sort: false,
      display: false,
      filter: false
    }
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      sort: false,
      filter: true
    }
  },
  {
    name: 'ACCOUNT_DESC',
    label: 'Account Desc',
    options: {
      sort: false,
      display: false,
      filter: false
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      sort: false,
      filter: true
    }
  },
  {
    name: 'UNITS_AMOUNT',
    label: 'Units',
    options: {
      sort: false,
      filter: false
    }
  },
  {
    name: 'POSTING_DATE',
    label: 'Posting Date',
    options: {
      customBodyRender: dateFormat,
      sort: false,
      filter: false
    }
  },
  {
    name: 'TRAN_AMOUNT',
    label: 'Tran Amount',
    options: {
      customBodyRender: currencyFormat,
      sort: true,
      filter: false
    }
  },
  {
    name: 'ACTIVITY_LINK',
    label: 'Activity',
    options: {
      download: false,
      sort: false,
      filter: false
    }
  },
  {
    name: 'GTMXVALUE',
    label: 'Attributes',
    options: {
      download: false,
      sort: false,
      filter: false
    }
  },
  {
    name: 'MORE',
    label: 'More Info',
    options: {
      download: false,
      sort: false,
      filter: false
    }
  },
  {
    name: 'SOURCE_CODE',
    label: 'Source Code',
    options: {
      display: false,
      filter: false
    }
  },
  {
    name: 'REFERENCE',
    label: 'Reference',
    options: {
      display: false,
      sort: false,
      filter: false
    }
  },
  {
    name: 'BASE_AMOUNT',
    label: 'Base Amount',
    options: {
      customBodyRender: currencyFormat,
      display: false,
      sort: false,
      filter: false
    }
  },
  {
    name: 'AUTO_REV',
    label: 'Auto Rev',
    options: {
      display: false,
      sort: false,
      filter: false
    }
  },
  {
    name: 'ACTIVITY',
    label: 'Activity',
    options: {
      display: false,
      sort: false,
      filter: false
    }
  },
  {
    name: 'ACCT_CATEGORY',
    label: 'Category',
    options: {
      display: false,
      sort: false,
      filter: false
    }
  },
  {
    name: 'ActCatDescription',
    label: 'Catergory Description',
    options: {
      display: false,
      sort: false,
      filter: false
    }
  },
  {
    name: 'ActDescription',
    label: 'Description',
    options: {
      display: false,
      sort: false,
      filter: false
    }
  }
]

export const glChartReactTableCols = [
  {
    accessorKey: 'CHART_SECTION',
    header: 'Section',
    filterFn: 'contains'
  },
  {
    accessorKey: 'SEQ_NUMBER',
    header: 'Number',
    filterFn: 'contains'
  },
  {
    accessorKey: 'DEPTH',
    header: 'Depth',
    filterFn: 'contains'
  },
  {
    accessorKey: 'SUMMARY_ACCT',
    header: 'Summary',
    filterFn: 'contains'
  },
  {
    accessorKey: 'DESC',
    header: 'Description',
    filterFn: 'contains'
  },
  {
    accessorKey: 'ACCOUNT',
    header: 'Account',
    filterFn: 'contains'
  },
  {
    accessorKey: 'SUB_ACCOUNT',
    header: 'Sub Account',
    filterFn: 'contains'
  },
  {
    accessorKey: 'ACCOUNT_DESC',
    header: 'Account Description',
    filterFn: 'contains'
  }
]

export const glTransServerSideCols2 = [
  {
    accessorKey: 'SYSTEM',
    header: 'System',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'JE_TYPE',
    header: 'JE Type',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'CONTROL_GROUP',
    header: 'Control Group',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'LINE_NBR',
    header: 'Line',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'ACCT_UNIT',
    header: 'Account Unit',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'UNIT_DESCRIPTION',
    header: 'Unit Desc',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'ACCOUNT',
    header: 'Account',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'ACCOUNT_DESC',
    header: 'Account Desc',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Description',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'UNITS_AMOUNT',
    header: 'Units',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'POSTING_DATE',
    header: 'Posting Date',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'TRAN_AMOUNT',
    header: 'Tran Amount',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'SOURCE_CODE',
    header: 'Source Code',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'REFERENCE',
    header: 'Reference',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'BASE_AMOUNT',
    header: 'Base Amount',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'AUTO_REV',
    header: 'Auto Rev',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'TO_COMPANY',
    header: 'To Company',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'ACTIVITY_LINK',
    header: 'Activity',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    dontDownload: true
  },
  {
    accessorKey: 'ACCT_CATEGORY',
    header: 'Category',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'ActCatDescription',
    header: 'Catergory Description',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'ActDescription',
    header: 'Description',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  }
  // {
  //   accessorKey: 'ACTIVITY_LINK',
  //   header: 'Activity Link',
  //   enableSorting: false,
  //   enableColumnFilter: false,
  //   size: 1,
  //   dontDownload: true
  // },
]

export const budgetCols = [
  {
    accessorKey: 'PRD',
    header: 'Period',
    filterFn: 'contains'
  },
  {
    accessorKey: 'FACTOR',
    header: 'Factor',
    filterFn: 'contains'
  },
  {
    accessorKey: 'UNITS',
    header: 'Units',
    filterFn: 'contains'
  },
  {
    accessorKey: 'RATE',
    header: 'Rate',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'AMOUNT',
    header: 'Amount',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  }
]

export const trialBalanceCols = [
  {
    accessorKey: 'COMP',
    header: 'Co',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'CHART',
    header: 'Chart',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'FY',
    header: 'Year',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'PRD',
    header: 'Prd',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'ACCT_UNIT',
    header: 'Acct Unit',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'ACCT',
    header: 'Acct',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'SUB_ACCT',
    header: 'Sub Acct',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'AU_DESC',
    header: 'AU',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'ACCT_DESC',
    header: 'Description',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'BEG_BAL',
    header: 'Beg Bal',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'DEBITS',
    header: 'Debits',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'CREDITS',
    header: 'Credits',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'END_BAL',
    header: 'End Bal',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  }
]

export const journalControlCols = [
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'FISCAL_YEAR',
    header: 'Year',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'ACCT_PERIOD',
    header: 'Period',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'SYSTEM',
    header: 'System',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: true
  },
  {
    accessorKey: 'JE_TYPE',
    header: 'Type',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: true
  },
  {
    accessorKey: 'CONTROL_GROUP',
    header: 'Control Group',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: true
  },
  {
    accessorKey: 'POSTING_DATE',
    header: 'Posting Date',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Desc',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: true
  },
  {
    accessorKey: 'STATUS',
    header: 'Status',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'OPERATOR',
    header: 'Operator',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: true
  },
  {
    accessorKey: 'REL_OPERATOR',
    header: 'Rel Operator',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'ENTRY_DB',
    header: 'Entry Db',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'ENTRY_CR',
    header: 'Entry CR',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'AUTO_REV',
    header: 'Auto Rev',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'VIEW',
    header: 'VIEW',
    filterFn: 'contains',
    enableSorting: false,
    enableColumnFilter: false
  }
]

export const trialBalanceConsolidatedCols = [
  {
    accessorKey: 'FISCAL_YEAR',
    header: 'Year',
    filterFn: 'contains'
  },
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    filterFn: 'contains'
  },
  {
    accessorKey: 'ACCOUNT',
    header: 'Account',
    filterFn: 'contains'
  },
  {
    accessorKey: 'SUB_ACCOUNT',
    header: 'Sub Account',
    filterFn: 'contains'
  },
  {
    accessorKey: 'TRANS_TYPE',
    header: 'Type',
    filterFn: 'contains'
  },
  {
    accessorKey: 'BEGIN_BAL',
    header: 'Begin Balance',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'DEBITS',
    header: 'Debits',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'CREDITS',
    header: 'Credits',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'END_BAL',
    header: 'End Balance',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'GL_TRANS_VIEW',
    header: 'Transactions'
  }
]
